import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    Badge,
} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { QRCodeSVG } from "qrcode.react";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";
import config from "config.json";

import bitcoinSvg from "../assets/img/gatewayPayment/bitcoin.svg";
import ethereumSvg from "../assets/img/gatewayPayment/ethereum.svg";
import litecoinSvg from "../assets/img/gatewayPayment/litecoin.svg";
import moneroSvg from "../assets/img/gatewayPayment/monero.svg";

function Plans(props) {
    const navigate = useNavigate();
    const [step, setStep] = React.useState(1);
    const [offer, setOffer] = React.useState("residential");
    const [subscribeTime, setSubscribeTime] = React.useState("1");
    const [paymentMethod, setPaymentMethod] = React.useState("BTC");
    const [color, setColor] = React.useState("#f7931a");

    React.useEffect(() => {
        switch (paymentMethod) {
            case "BTC":
                setColor("#f7931a");
                break;
            case "ETH":
                setColor("#3c3c3d");
                break;
            case "LTC":
                setColor("#1f1f1f");
                break;
            case "XMR":
                setColor("#ff6600");
                break;
            default:
                setColor("#f7931a");
                break;
        }
    }, [paymentMethod]);

    const onChangeOffer = (e) => {
        setOffer(e.target.value);
    };

    const onChangeSubscribeTime = (e) => {
        setSubscribeTime(e.target.value);
    };

    const onChangePaymentMethod = (e) => {
        setPaymentMethod(e.target.value);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied to clipboard");
    };

    const [payment, setPayment] = React.useState({});
    const createPayment = async () => {
        const response = await fetch(
            `${config.protocol}://${config.host}/api/subscription`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                    subscribeTime: subscribeTime,
                    crypto: paymentMethod,
                    offer: offer,
                }),
            }
        );
        const data = await response.json();
        if (!data.error) {
            setPayment(data);
            setStep(4);
        } else {
            toast.error(data.message);
        }
    };

    const [cancelTime, setCancelTime] = React.useState(60);
    useEffect(() => {
        const interval = setInterval(() => {
            setCancelTime(cancelTime - 1);
        }, 60000);
        return () => clearInterval(interval);
    }, [payment, cancelTime]);

    const {
        //isLoading: isPaymentInfoLoading,
        data: paymentInfo,
        //refetch: reFetchPaymentInfo,
    } = useQuery({
        queryKey: ["paymentInfo"],
        enabled: step === 4,
        refetchInterval: 60000,
        queryFn: () =>
            fetch(
                `${config.protocol}://${config.host}/api/subscription/${payment.uniqid}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                    },
                }
            ).then((res) => res.json()),
    });

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="8">
                        <Row>
                            <Col lg="6">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            Proxies Residential
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <ul>
                                            <li>
                                                Reputable Internet Providers
                                            </li>
                                            <li>Unlimited Bandwidth</li>
                                            <li>No Log</li>
                                        </ul>
                                        <p className="text-center">
                                            10€ / month
                                        </p>
                                        {localStorage.getItem(
                                            "subscriptions"
                                        ) &&
                                            JSON.parse(
                                                localStorage.getItem(
                                                    "subscriptions"
                                                )
                                            ).find(
                                                (sub) =>
                                                    sub.typeSubscription ===
                                                    "residential"
                                            ) && (
                                                <div className="text-center mt-2">
                                                    <Button
                                                        color="success"
                                                        onClick={() =>
                                                            navigate(
                                                                "/_/proxies/residential"
                                                            )
                                                        }
                                                    >
                                                        Access (expire:{" "}
                                                        {new Date(
                                                            Number(
                                                                JSON.parse(
                                                                    localStorage.getItem(
                                                                        "subscriptions"
                                                                    )
                                                                ).find(
                                                                    (sub) =>
                                                                        sub.typeSubscription ===
                                                                        "residential"
                                                                ).endDate
                                                            )
                                                        ).toLocaleDateString()}
                                                        )
                                                    </Button>
                                                </div>
                                            )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            Proxies Datacenters
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <ul>
                                            <li>Private proxies</li>
                                            <li>Unlimited Bandwidth</li>
                                            <li>No Log</li>
                                        </ul>
                                        <p className="text-center">
                                            2€ / month
                                        </p>
                                        {localStorage.getItem(
                                            "subscriptions"
                                        ) &&
                                            JSON.parse(
                                                localStorage.getItem(
                                                    "subscriptions"
                                                )
                                            ).find(
                                                (sub) =>
                                                    sub.typeSubscription ===
                                                    "datacenter"
                                            ) && (
                                                <div className="text-center mt-2">
                                                    <Button
                                                        color="success"
                                                        onClick={() =>
                                                            navigate(
                                                                "/_/proxies/datacenter"
                                                            )
                                                        }
                                                    >
                                                        Access (expire:{" "}
                                                        {new Date(
                                                            Number(
                                                                JSON.parse(
                                                                    localStorage.getItem(
                                                                        "subscriptions"
                                                                    )
                                                                ).find(
                                                                    (sub) =>
                                                                        sub.typeSubscription ===
                                                                        "datacenter"
                                                                ).endDate
                                                            )
                                                        ).toLocaleDateString()}
                                                        )
                                                    </Button>
                                                </div>
                                            )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="4">
                        <Card>
                            <CardBody>
                                {step === 1 && (
                                    <>
                                        <FormGroup>
                                            <Label>Choice Offer</Label>
                                            <Input
                                                type="select"
                                                defaultValue="1"
                                                onChange={onChangeOffer}
                                            >
                                                <option value="residential">
                                                    Proxies Residential
                                                </option>
                                                <option value="datacenter">
                                                    Proxies Datacenter
                                                </option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>
                                                For how long do you want to
                                                subscribe?
                                            </Label>
                                            <Input
                                                type="select"
                                                defaultValue="1"
                                                onChange={onChangeSubscribeTime}
                                            >
                                                <option value="1">
                                                    1 Month
                                                </option>
                                                <option value="3">
                                                    3 Month
                                                </option>
                                                <option value="6">
                                                    6 Month
                                                </option>
                                                <option value="12">
                                                    12 Month
                                                </option>
                                                <option value="24">
                                                    24 Month
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <FormGroup>
                                            <Label for="exampleSelect">
                                                Choose a payment method
                                            </Label>
                                            <Input
                                                type="select"
                                                name="select"
                                                id="exampleSelect"
                                                defaultValue="BTC"
                                                onChange={onChangePaymentMethod}
                                            >
                                                <option value="BTC">
                                                    Bitcoin
                                                </option>
                                                <option value="ETH">
                                                    Ethereum
                                                </option>
                                                <option value="LTC">
                                                    Litecoin
                                                </option>
                                                <option value="XMR">
                                                    Monero
                                                </option>
                                            </Input>
                                        </FormGroup>
                                        <p>
                                            We recommend this decentralized
                                            wallet, you can also buy
                                            cryptocurrency from it{" "}
                                            <Link to="https://www.exodus.com">
                                                Exodus
                                            </Link>
                                        </p>
                                    </>
                                )}

                                {step === 3 && (
                                    <>
                                        <p>Summary</p>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>Subscribe Time</td>
                                                    <td>
                                                        {subscribeTime} Month
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Payment Method</td>
                                                    <td>
                                                        {
                                                            <img
                                                                src={
                                                                    paymentMethod ===
                                                                    "BTC"
                                                                        ? bitcoinSvg
                                                                        : paymentMethod ===
                                                                          "ETH"
                                                                        ? ethereumSvg
                                                                        : paymentMethod ===
                                                                          "LTC"
                                                                        ? litecoinSvg
                                                                        : moneroSvg
                                                                }
                                                                alt="paymentMethod"
                                                                width="20"
                                                            />
                                                        }{" "}
                                                        {paymentMethod === "BTC"
                                                            ? "Bitcoin"
                                                            : paymentMethod ===
                                                              "ETH"
                                                            ? "Ethereum"
                                                            : paymentMethod ===
                                                              "LTC"
                                                            ? "Litecoin"
                                                            : "Monero"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Price</td>
                                                    <td>
                                                        €
                                                        {(offer ===
                                                        "residential"
                                                            ? 10
                                                            : 2) *
                                                            subscribeTime}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                )}

                                {step === 4 && (
                                    <>
                                        <p>Payment Details</p>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>Subscribe Time</td>
                                                    <td>
                                                        {subscribeTime} Month
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Payment Method</td>
                                                    <td>
                                                        {
                                                            <img
                                                                src={
                                                                    paymentMethod ===
                                                                    "BTC"
                                                                        ? bitcoinSvg
                                                                        : paymentMethod ===
                                                                          "ETH"
                                                                        ? ethereumSvg
                                                                        : paymentMethod ===
                                                                          "LTC"
                                                                        ? litecoinSvg
                                                                        : moneroSvg
                                                                }
                                                                alt="paymentMethod"
                                                                width="20"
                                                            />
                                                        }{" "}
                                                        {paymentMethod === "BTC"
                                                            ? "Bitcoin"
                                                            : paymentMethod ===
                                                              "ETH"
                                                            ? "Ethereum"
                                                            : paymentMethod ===
                                                              "LTC"
                                                            ? "Litecoin"
                                                            : "Monero"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Price</td>
                                                    <td>
                                                        €
                                                        {(offer ===
                                                        "residential"
                                                            ? 10
                                                            : 2) *
                                                            subscribeTime}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Canceled in</td>
                                                    <td>
                                                        {cancelTime +
                                                            " minutes"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        {paymentInfo &&
                                                            paymentInfo.status}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {paymentInfo &&
                                            paymentInfo.status !==
                                                "COMPLETED" && (
                                                <>
                                                    <div className="mt-2 mt-2 d-flex justify-content-center">
                                                        <div
                                                            style={{
                                                                background:
                                                                    "#fff",
                                                                padding: "10px",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <QRCodeSVG
                                                                value={
                                                                    payment &&
                                                                    payment.crypto_uri
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 d-flex justify-content-around">
                                                        <p>Send</p>
                                                        <Badge
                                                            color="primary"
                                                            style={{
                                                                fontSize:
                                                                    "15px",
                                                                cursor: "pointer",
                                                                background:
                                                                    color,
                                                            }}
                                                            onClick={() =>
                                                                copyToClipboard(
                                                                    payment &&
                                                                        payment.crypto_amount
                                                                )
                                                            }
                                                        >
                                                            {payment &&
                                                                payment.crypto_amount}
                                                        </Badge>
                                                        <p>to</p>
                                                    </div>
                                                    <div className="mt-2">
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                value={
                                                                    payment &&
                                                                    payment.crypto_address
                                                                }
                                                                readOnly
                                                                className="text-light"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    copyToClipboard(
                                                                        payment &&
                                                                            payment.crypto_address
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </>
                                            )}
                                    </>
                                )}

                                <div className="text-right mt-2">
                                    <ButtonGroup>
                                        {step > 1 && step !== 4 && (
                                            <Button
                                                color="dark"
                                                onClick={() =>
                                                    setStep(step - 1)
                                                }
                                            >
                                                Back
                                            </Button>
                                        )}
                                        {step !== 4 && (
                                            <Button
                                                color="success"
                                                onClick={() => {
                                                    step < 3
                                                        ? setStep(step + 1)
                                                        : createPayment();
                                                }}
                                            >
                                                Next
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Plans;
